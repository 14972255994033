.slider {
    overflow: hidden;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
    border-radius: 20px;
    background: var(--secondary-color);
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: .4rem 0;
    align-self: stretch;
    --slider-color: #eba563;
}
.slider:disabled::-webkit-slider-thumb {
    display: none;
}
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    /* aspect-ratio: 1; */
    border-radius: 5%;
    background: var(--bg-color);
    cursor: pointer;
    box-shadow: -200px 0 0 200px var(--slider-color);
}
.slider::-webkit-slider-runnable-track {
    height: 100%;
    -webkit-appearance: none;
    color: var(--slider-color);
}
