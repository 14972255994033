/* global CSS styles */
:root {
    --text-color: #4b4b4b;
    --bg-color: #f7f7f7;
    --card-color: #eaebec;
    --main-color: #eba563;
    --secondary-color: #cbced0;
    --circleprogress-color: #FF1E1E;
    --circleprogbg-color: #CBCED0;
    --scrollbar-color: #aaaaaa;
    --scrollbar-width: 8px;
}
html, body, #root, main {
    height: 100%;
    width: 100%;
}
main {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    color: var(--text-color);
    background: var(--bg-color);
    overflow: hidden;
}
h1 {
    margin-top: 0;
    margin-bottom: .4em;
}
::-webkit-scrollbar {
    width: var(--scrollbar-width);
}
::-webkit-scrollbar-track {
    background-color: var(--bg-color);
}
::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.mb-row {
    flex-direction: column;
}

@media (max-width: 30rem) {
    .mb-col {
        flex-direction: column-reverse;
    }
    .mb-row, div.mb-row {
        flex-direction: row;
        justify-content: space-between;
    }
    h1 {
        margin-bottom: 0;
    }
}
